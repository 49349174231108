import { toTitleCase } from '$lib/utils/helpers';
import { TOAST_N } from '$lib/stores/stores';

// Toast Notification

export const toastNotify = (message, type) => {
	TOAST_N.set({
		status: true,
		message: toTitleCase(message),
		kind: type,
		title: toTitleCase(type)
	});
};
