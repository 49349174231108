import { connector } from './connector';

export async function GET_CLASSROOMS(callback, onError, term_id = '') {
	try {
		let url = `/classrooms?term_id=${term_id}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

/* export async function GET_CLASSROOMS(term_id, callback, onError) {
	try {
		let url = `/classrooms?term_id=${term_id}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
} */

export async function GET_CLASSROOM(id, callback, onError) {
	try {
		let url = `/classrooms/${id}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_ALL_CLASSARMS(callback, onError) {
	try {
		let url = `/classrooms/arms`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_ALL_CLASS_SECTIONS(callback, onError) {
	try {
		let url = `/classrooms/sections`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_CLASSARMS_BY_SECTION(section, callback, onError) {
	try {
		let url = `/classrooms/arms?section=${section}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function DELETE_CLASSROOMS(class_ids, session, term_id, callback, onError) {
	try {
		let url = `/classrooms?classroom_id=${class_ids}&term=${term_id}&session=${session}`;
		let res = await connector.delete(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function POST_PUT_CLASSROOM(
	name,
	section,
	classarm,
	teacher,
	subject,
	term,
	session,
	class_id,
	type,
	callback,
	onError
) {
	let payload = { name, section, classarm, teacher, subject, term, session };
	try {
		let url;
		let res;
		if (type === 'create') {
			url = `/classrooms`;
			res = await connector.post(`${url}`, payload);
		} else if (type === 'update') {
			url = `/classrooms/${class_id}`;
			res = await connector.put(`${url}`, payload);
		} else {
			return false;
		}
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}
