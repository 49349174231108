import axios from 'axios';
import { browser } from '$app/environment';
import { toastNotify } from '@components/toast';
import { get } from 'svelte/store';
import { TOKEN_CHECK } from '$lib/stores/stores';

// let api = 'https://schoolmo-backend-tvni.onrender.com/api';
export const api = import.meta.env.VITE_API_URL;
// export const api = 'http://localhost:3002/api';
// export const api = 'https://schoolmo-backend.up.railway.app/api';
export const connector = axios.create({
	baseURL: api,
	timeout: 25000,
	maxContentLength: 60000000,
	transformResponse: axios.defaults.transformResponse.concat((data) => {
		if (
			data.status === 'error' &&
			(data.message === 'jwt expired' ||
				data.message === 'jwt malformed' ||
				data.message === 'jwt must be provided')
		) {
			toastNotify('Token Expired', 'error');
			localStorage.removeItem('USER_DETAILS');
			localStorage.removeItem('token');
			localStorage.removeItem('age');
			localStorage.setItem('CURRENT_URL', window.location.pathname);
			if (!get(TOKEN_CHECK)) {
				return (window.location = '/login');
			}
		}
		if (data.status === 'error') {
			if (get(TOKEN_CHECK)) {
				toastNotify('Login is Required', 'error');
			} else {
				toastNotify(data.message, 'error');
			}
		}
		// store refresh token
		data?.token && localStorage.setItem('token', JSON.stringify(data.token));
		data?.token && localStorage.setItem('age', new Date().getTime());

		// console.log('TOKEN', get(TOKEN));

		TOKEN_CHECK.set(false);
		return data;
	}),
	validateStatus: function (status) {
		return status >= 200 && status < 400;
	}
});
connector.interceptors.request.use(
	function (config) {
		config.headers = {
			...config.headers
		};
		let token =
			browser && localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';

		config.headers.Accept = 'application/json';
		config.headers['Content-Type'] = 'application/json';
		config.headers['Access-Control-Allow-Origin'] = '*';
		config.headers.Authorization = `Bearer ${token}`;

		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
connector.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		// if (401 == error.response.status) {
		// }
		return Promise.reject(error);
	}
);
